@import "../mixins/btn";
/*
ボタンサイズ

ボタンサイズを変更するスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 2.1
*/

/*
通常ボタン

インラインの要素としてボタンを定義出来ます。

ex [トップページ　ボタン部分](http://demo3.ec-cube.net/)

Markup:
.ec-inlineBtn 住所検索
.ec-inlineBtn--primary もっと見る
.ec-inlineBtn--action カートに入れる
.ec-inlineBtn--cancel キャンセル

Styleguide 2.1.1
*/
.ec-inlineBtn{
  @include btn-default;
}
.ec-inlineBtn--primary{
  @include btn-primary
}
.ec-inlineBtn--action{
  @include btn-action
}
.ec-inlineBtn--cancel{
  @include btn-cancel
}
.ec-inlineBtn--favorite{
  @include btn-favorite
}

/*
ブロックボタン（全幅）

ボタンサイズは em で指定するため、テキストサイズの変更でボタンサイズを変更できます。

ex [商品詳細ページ　カートボタン部分](http://demo3.ec-cube.net/products/detail/30)

Markup:
p: .ec-blockBtn 住所検索
p: .ec-blockBtn--primary もっと見る
p: .ec-blockBtn--action カートに入れる
p: .ec-blockBtn--cancel キャンセル

Styleguide 2.1.2
*/
.ec-blockBtn{
  @include blockBtn-default;
  &:hover{
    background-color: #fff;
  }
}
.ec-blockBtn--primary{
  @include blockBtn-primary
}
.ec-blockBtn--action{
  @include blockBtn-action
}
.ec-blockBtn--cancel{
  @include blockBtn-cancel
}
.ec-blockBtn--favorite{
  @include blockBtn-favorite
}

.button-cart2{
  &:hover {
    background: darken(#272828, 10%);
  }
}
.button-cart2_soldout{
  &:hover {
    background: #EFEFEF;
  }
  &._fin{
    .button-cart2__txt::before{
      left: 0;
    }
  }
}

.button-question2{
  &__link{
    display: block;
    padding: 15px;
  }
}