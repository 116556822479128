@import "../mixins/media";
/*
検索ラベル

商品一覧 ヘッダー部 に関する Project コンポーネントを定義します。

ex [商品一覧 ヘッダー部](http://demo3.ec-cube.net/products/list)

Markup:
include /assets/tmpl/elements/13.1.searchnav.pug
+ec-searchnavRole__topicpath
+ec-searchnavRole__info

Styleguide 13.1

*/
.ec-searchnavRole{
  /*
  margin-bottom: 0;
  padding: 0;
  text-align: left;
  @include media_desktop {
    //@include container;
  }
  & &__infos{
    @include container;
    display: flex;
    border-top: 0;
    margin-bottom: 0;
    padding-top: 5px;
    flex-direction:column;
    text-align: left;
    @include media_desktop {
      padding-left: 0;
      padding-right: 0;
      border-top: 1px solid #ccc;
      padding-top: 16px;
      flex-direction:row;
      margin-bottom: 16px;
    }
  }
  */

  & &__counter{
    margin-bottom: 16px;
    width: 100%;
    letter-spacing: 0.01rem;
    @include media_desktop {
      margin-bottom: 0;
      width: 50%;
    }
  }

  /*
  & &__actions{
    text-align: right;
    width: 100%;
    @include media_desktop {
      width: 50%;
    }
  }
  */


}


.filters2-color{
  //レッド
  .choosy-color-pattern-1+span::before{
    background-color: red;
  }
  //ブラック
  .choosy-color-pattern-2+span::before{
    background-color: #000;
  }
  //ブルー
  .choosy-color-pattern-3+span::before{
    background-color: #3b62e1;
  }
  //ホワイト
  .choosy-color-pattern-4+span::before{
    background-color: #fff;
    border: 1px solid #aaa;
  }
  //グリーン
  .choosy-color-pattern-5+span::before{
    background-color: #2d8330;
  }
  //ブラウン
  .choosy-color-pattern-6+span::before{
    background-color: #663718;
  }
  //グレー
  .choosy-color-pattern-7+span::before{
    background-color: #888888;
  }
  //イエロー
  .choosy-color-pattern-8+span::before{
    background-color: #fef147;
  }
  //ピンク
  .choosy-color-pattern-9+span::before{
    background-color: #e96cb5;
  }
  //オレンジ
  .choosy-color-pattern-10+span::before{
    background-color: #fd742c;
  }
  // パープル
  .choosy-color-pattern-11+span::before{
    background-color: #7843bc;
  }
  // ミックス
  .choosy-color-pattern-12+span::before{
    background: url(../img/mix.svg) no-repeat;
  }
  // ベージュ
  .choosy-color-pattern-13+span::before{
    background: #dcc9b2;
  }
  // ネイビー
  .choosy-color-pattern-14+span::before{
    background: #43589e;
  }
  // その他
  .choosy-color-pattern-15+span::before{
    background: url(../img/other.svg) no-repeat;
  }
}

@include media_desktop(){
  .filters2-color{
    //レッド
    .choosy-color-pattern-1+label span::before{
      background-color: red;
    }
    //ブラック
    .choosy-color-pattern-2+label span::before{
      background-color: #000;
    }
    //ブルー
    .choosy-color-pattern-3+label span::before{
      background-color: #3b62e1;
    }
    //ホワイト
    .choosy-color-pattern-4+label span::before{
      background-color: #fff;
      border: 1px solid #aaa;
    }
    //グリーン
    .choosy-color-pattern-5+label span::before{
      background-color: #2d8330;
    }
    //ブラウン
    .choosy-color-pattern-6+label span::before{
      background-color: #663718;
    }
    //グレー
    .choosy-color-pattern-7+label span::before{
      background-color: #888888;
    }
    //イエロー
    .choosy-color-pattern-8+label span::before{
      background-color: #fef147;
    }
    //ピンク
    .choosy-color-pattern-9+label span::before{
      background-color: #e96cb5;
    }
    //オレンジ
    .choosy-color-pattern-10+label span::before{
      background-color: #fd742c;
    }
    // パープル
    .choosy-color-pattern-11+label span::before{
      background-color: #7843bc;
    }
    // ミックス
    .choosy-color-pattern-12+label span::before{
      background: url(../img/mix.svg) no-repeat;
    }
    // ベージュ
    .choosy-color-pattern-13+label span::before{
      background: #dcc9b2;
    }
    // ネイビー
    .choosy-color-pattern-14+label span::before{
      background: #43589e;
    }
    // その他
    .choosy-color-pattern-15+label span::before{
      background: url(../img/other.svg) no-repeat;
    }
  } 

  .filters2-color{
    //レッド
    .choosy-color-pattern-1+span::before{
      background-color:initial;
    }
    //ブラック
    .choosy-color-pattern-2+span::before{
      background-color:initial;
    }
    //ブルー
    .choosy-color-pattern-3+span::before{
      background-color:initial;
    }
    //ホワイト
    .choosy-color-pattern-4+span::before{
      background-color:initial;
      border: none;
    }
    //グリーン
    .choosy-color-pattern-5+span::before{
      background-color:initial;
    }
    //ブラウン
    .choosy-color-pattern-6+span::before{
      background-color: initial;
    }
    //グレー
    .choosy-color-pattern-7+span::before{
      background-color: initial;
    }
    //イエロー
    .choosy-color-pattern-8+span::before{
      background-color: initial;
    }
    //ピンク
    .choosy-color-pattern-9+span::before{
      background-color: initial;
    }
    //オレンジ
    .choosy-color-pattern-10+span::before{
      background-color: initial;
    }
    // パープル
    .choosy-color-pattern-11+span::before{
      background-color: initial;
    }
    // ミックス
    .choosy-color-pattern-12+span::before{
      background: none;
    }
    // ベージュ
    .choosy-color-pattern-13+span::before{
      background: none;
    }
    // ネイビー
    .choosy-color-pattern-14+span::before{
      background: none;
    }
    // その他
    .choosy-color-pattern-15+span::before{
      background: none;
    }
  }
}

.filters2-pregnancy__item input:checked+label .filters2-pregnancy__name::after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 5px;
  height: 10px;
  transform: rotate(40deg);
  border-bottom: 2px solid #000000;
  border-right: 2px solid #000000;
}

.side-filters-toggle__btn-txt{
  width: 100%;

 .side-filters-toggle__link{
    position: initial;
    color: #fff;
    width: 100%;

    &:hover{
      text-decoration: none;
    }
  }
}

.filters2-button__text {
  .filters2-button__link {
      position: initial;
      color: #fff; 

      &:hover{
        text-decoration: none;
      } 
  }
}

.slide-nav-news__txt_sp{
  .slide-nav-news__link{
    letter-spacing: normal;
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.5;
    width: calc(100% - 42px);
    float: right;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-top: -4px;
    color: #272828;
    text-decoration: none; 
    position: initial;
  }
}

.slide-nav-guide__txt{
  .slide-nav-guide__link{
    position: initial;
  }
}