@import "../mixins/media";
@import "../mixins/projects";
@import "../mixins/price";

/*
注文履歴一覧

マイページ 注文履歴部に関する Project コンポーネントを定義します。

ex [マイページ　注文履歴一覧](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyRole

Styleguide 16.1
*/
.ec-historyRole{
  & &__contents{
    padding-top: 1em;
    padding-bottom: 16px;
    border-top: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    color: #525263;
    @include media_desktop {
      flex-direction: row;
    }
  }
  & &__header{
    width: 100%;
    @include media_desktop {
      width: 33.3333%;
    }
  }
  & &__detail{
    @include borderTop;
    width: 100%;

    .ec-imageGrid:nth-of-type(1) {
      border-top: none;
    }

    .ec-historyRole__detailTitle {
      margin-bottom: 8px;
      font-size: 13px;
      font-weight: bold;
    }

    .ec-historyRole__detailPrice {
      margin-top: 8px;
      margin-bottom: 8px;
      font-size: 16px;
      display: block;
    }

    @include media_desktop {
      width: 66.6666%;
      border-top: none;
    }
  }
}

/*
注文履歴一覧 規格

マイページ 注文履歴内アイテムの規格を定義します。

ex [マイページ　注文履歴一覧](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyRole-option

Styleguide 16.1.1
*/

.ec-historyRole{
  & &__detail {
    .ec-historyRole__detailOption {
      display: inline-block;
      margin-bottom: 8px;
      margin-right: .5rem;
      font-size: 1.6rem;
    }
    .ec-historyRole__detailOption::after {
      display: inline-block;
      padding-left: .5rem;
      //content: "/";
      font-weight: bold;
    }
  }
}

/*
注文履歴一覧ヘッダ

注文履歴一覧で使用するヘッダのコンポーネントを定義します。

ex [マイページ　注文履歴一覧ヘッダ](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyHeader
p hofe

Styleguide 16.1.2
*/


.ec-historyListHeader{
  & &__date{
    font-weight: bold;
    font-size: 16px;
    @include media_desktop {
      font-weight: bold;
      font-size: 20px;
    }
  }
  & &__action{
    margin : 16px 0;
    a {
      font-size: 12px;
      font-weight: normal;
      @include media_desktop {
        font-size: 14px;
      }
    }
  }
}