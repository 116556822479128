@import "../mixins/media";
/*

ユニサーチ

*/

@mixin rec($maxWith, $mRight, $flexBasis,$warp,$header:'RECOMMEND', $title: 'あなたにおすすめの商品') {
    width: $maxWith;
    margin-left: auto;
    margin-right: auto;

    .dv_rc{

        &_header{
            margin-bottom: 10px;
//            &::after{
//                content: $header;
//            }
        }

        &_title{
            margin-bottom: 30px;
//            &::after{
//                content: $title;
//            }
        }

        &_items{
            flex-basis: $flexBasis;

            margin-right: $mRight;

            &:nth-of-type(#{$warp}n){
                margin-right: 0;
                @include media_desktop(){
                    margin-right: $mRight;
                }
            }
            &:nth-child(3){
                margin-right: $mRight;
            }
        }
    }
}

#recommender{
    // トップページ
    &_pc111{
        @include rec(1100px,22px , ( (1100px - (22px * 5)) / 5),5);
    }

    &_sp111{
        @include rec(100%,5px , calc((100% - 20px)/3),3);
    }

    // カテゴリトップ
    &_pc211{
        @include rec(790px,22px , ( (790px - (22px * 4)) / 4),4);
    }

    &_sp211{
        @include rec(100%,15px , calc((100% - 20px)/2),2);
    }

    // 商品一覧
    &_pc215{
        @include rec(790px,22px , ( (790px - (22px * 4)) / 4),4);
    }

    &_sp215{
        @include rec(100%,15px , calc((100% - 20px)/2),2);
    }

    // 商品一覧
    &_pc215{
        @include rec(790px,22px , ( (790px - (22px * 4)) / 4),4);
    }

    &_sp215{
        @include rec(100%,15px , calc((100% - 20px)/2),2);
    }

    &_pc711{
        @include rec(790px,22px , ( (790px - (22px * 4)) / 4),4);
    }

    &_sp711{
        @include rec(100%,15px , calc((100% - 20px)/2),2);
    }

    // 商品詳細
    &_pc311{
        @include rec(1100px,22px , ( (1100px - (22px * 5)) / 5),5);
    }

    &_sp311{
        @include rec(100%,15px , calc((100% - 20px)/2),2);
    }

    // カート
    &_pc411{
        margin-top: 30px;
        @include rec(1100px,22px , ( (1100px - (22px * 5)) / 5),5);
    }

    &_sp411{
        margin-top: 30px;
        @include rec(100%,15px , calc((100% - 20px)/2),2);
        .dv_rc_title{
            margin-bottom: 15px;
        }
    }

    // 注文完了
    &_pc511{

        position: relative;
        left: -275px;
        padding-top: 30px;

        @include rec(1100px,22px , ( (1100px - (22px * 5)) / 5),5,'この商品を購入した人は、次にこんな商品を購入しています。','');

        .dv_rc_title{
            margin-bottom: 0;
        }
        .dv_recommend .dv_rc_header:after {
            font-size: 20px;
        }

    }

    &_sp511{
        @include rec(100%,15px , calc((100% - 20px)/2),2,'この商品を購入した人は、次にこんな商品を購入しています。','');

        .dv_rc_title{
            margin-bottom: 0;
        }
        .dv_recommend .dv_rc_header:after {
            font-size: 15px;
        }
    }

    //マイページ
    &_pc611{
        padding-top: 50px;
        @include rec(1100px,22px , ( (1100px - (22px * 5)) / 5),5);
    }

    &_sp611{
        padding-top: 30px;
        @include rec(100%,5px , calc((100% - 20px)/3),3);
    }

    // おすすめ一覧
    &_pc811{
        @include rec(1100px,22px , ( (1100px - (22px * 5)) / 5),5);
    }

    &_sp811{
        @include rec(100%,5px , calc((100% - 20px)/3),3);
    }

}