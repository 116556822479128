@import "./variables";
@import "./clearfix";

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */



//@mixin media_tablet(){
//  @media only screen and (min-width: 768px) {
//    @content;
//  }
//}

@mixin media_desktop(){
  @media only screen and (min-width: 768px) {
    @content;
  }
}

//@mixin media_desktop2(){
//  @media only screen and (min-width: 768px) {
//    @content;
//  }
//}
//
//@mixin media_desktop3(){
//  @media only screen and (min-width: 768px) {
//    @content;
//  }
//}


@mixin container(){
  margin: 0 auto;
  padding-left:  10px;
  padding-right: 10px;
  box-sizing: border-box;
  margin-bottom: 60px;
  @include clearfix;
  @include commonStyle();
  width: 100%;
  max-width: 1130px;
  text-align: left;

  //@media (min-width: $desktop) {
  //  width: 720 + 30px;
  //}
  //@media (min-width: $desktop2) {
  //  width: 940 + 30px;
  //}
  //@media (min-width: $desktop3) {
  //  width: 1140 + 30px;
  //}
}
@mixin mypageContainer(){
  margin-right: auto;
  margin-left: auto;
  padding-left:  16px;
  padding-right: 16px;
  box-sizing: border-box;
  text-align: left;
  @include clearfix;
  @include commonStyle();
  width: 100%;
  //max-width: 1130px;
  @include media_desktop {
    padding-left:  26px;
    padding-right: 26px;
  }
}

@mixin commonStyle(){
  font-size: 14px;
  line-height: 1.4;
  color: #272828;
  -webkit-text-size-adjust: 100%;

  //a {
  //color: #0092C4;
  //color: #A092C4;
  //text-decoration: none;
  //cursor: pointer;
  //}
  //a:hover,
  //a:focus,
  //a:active { color: #33A8D0;text-decoration: none; outline: none;}


  textarea { /* for chrome fontsize bug */
    font-family: sans-serif;
  }

  //ul, ol {
  //  list-style: none;
  //  margin: 0; padding: 0;
  //}
  //dl, dt, dd, li{
  //  margin: 0; padding: 0;
  //}
  img {
    max-width: 100%;
  }

  html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }

  img{
    width: 100%;
  }


}
