@import "../mixins/media";
/*
ランキング一覧画面
*/

.ec-rankingListRole{
	margin-top: 40px;
	margin-bottom: 65px;

	.result-ranking{

		&__inner{
			@include media_desktop(){
				width: 1100px;
			}
		}
	}

	.contents{
		&__result-heading{
			text-align: left;
			margin-bottom: 22px;
			@include media_desktop(){
				margin-bottom: 42px;
			}

			h1{
				font-weight: normal;

				@include media_desktop(){
					font-size: 34px;
				}
			}

			h2{
				font-weight: normal;
				padding:0;
				@include media_desktop(){
					font-size: 34px;
				}
			}


		}
	}

	.ec-rankingCategoryItem{
		text-align: left;
		margin-bottom: 50px;
		h3{
			margin: 0 0 10px 0;
			letter-spacing:0.01em
		}
	}
}

.ec-rankingTop{
	padding-bottom: 65px;
	@include media_desktop(){
		padding-bottom: 65px;
	}
}
