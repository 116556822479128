@import "../mixins/media";
/*
商品一覧フッター

商品一覧 フッター に関する Project コンポーネントを定義します。

ex [商品一覧 ページャ部](http://demo3.ec-cube.net/products/list)

Markup:
include /assets/tmpl/elements/13.3.pager.pug
+ec-pagerRole

Styleguide 13.3

*/
.ec-pagerRole{
   text-align: center;
  .pager{
		margin-bottom: 15px;
		@include media_desktop(){
			margin: 0;
		}
    .pager-links{
			&__numbers{
				margin: 0 9px;
				@include media_desktop(){
					margin: 0 10px;
				}
			}
    }
  }
}
