@import "../mixins/media";

/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.1.product.pug
+ec-productSimpleRole

Styleguide 14.1
*/
.ec-productRole {
  //@include container;
  /*
  & &__img {
    margin-right: 0;
    margin-bottom: 20px;
    @include media_desktop {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
  & &__profile {
    margin-left: 0;
    @include media_desktop {
      margin-left: 16px;
    }
  }
  & &__title {
    .ec-headingTitle {
      font-size: 20px;
      @include media_desktop {
        font-size: 32px;
      }
    }
  }
  & &__tags {
    margin-top: 16px;
    padding: 0;
    padding-bottom: 16px;
    border-bottom: 1px dotted #ccc;
  }
  & &__tag {
    display: inline-block;
    padding: 2px 5px;
    list-style: none;
    font-size: 80%;
    color: #525263;
    border: solid 1px #D7DADD;
    border-radius: 3px;
    background-color: #F5F7F8;
  }
  & &__priceRegular {
    padding-top: 14px
  }
  & &__priceRegularTax {
    margin-left: 5px;
    font-size: 12px;
  }
  & &__price {
    color: #DE5D50;
    font-size: 28px;
    padding: 0;
    border-bottom: 0;
    @include media_desktop {
      padding: 14px 0;
      border-bottom: 1px dotted #ccc;
    }
  }*/
  /*
  & &__code {
    padding: 14px 0;
    border-bottom: 1px dotted #ccc;
  }
  */
  & &__category {
    //padding: 14px 0;
    //border-bottom: 1px dotted #ccc;
    //a {
    //  color: #33A8D0;
   // }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
  & &__actions {
    text-align: left;
  }
  & &__btn {
    &_stockinfo{
      text-align: left;
      margin-bottom: 5px;
    }
  }
  /*
  & &__actions {
    padding: 14px 0;
    .ec-select {
      select {
        height: 40px;
        max-width: 100%;
        min-width: 100%;
        @include media_desktop {
          min-width: 350px;
          max-width: 350px;
        }
      }
    }
  }
  & &__btn {
    width: 100%;
    margin-bottom: 10px;
    @include media_desktop {
      width: 60%;
      margin-bottom: 16px;
      min-width: 350px;
    }
  }*/
  /*
  & &__description {
    margin-bottom: 16px;
  }
  */
  & &__tag {
    margin: 10px 0;
  }


  // 以下は独自追加
  & &__PcCartInForm{
    display: none;
    @include media_desktop(){
      display: block;
    }

  }

  & &__spCartInBtn{
    @include media_desktop(){
      display: none;
    }
    ul{
      li{
        margin-bottom: 20px;
      }
    }
  }

  .area-product-detail{
    &__right{
      z-index: initial;


    }
  }

  .product-photos{
    a{
      &:hover{
        img{
          opacity: initial;
        }
      }
    }
    .swiper-button-prev{
      @include media_desktop(){
        left:-50px
      }
    }
    .swiper-button-next{
      @include media_desktop(){
        right: auto;
        left:500px
      }
    }
  }

  &__coordinate {
    margin-bottom: 20px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .itemlist-info__price{
      margin-bottom: 0;
      display: inline-block;
    }

    .itemlist-info__price-number{
      display: initial;
      font-size: 10px;
    }

    ._color-cancel{
      text-decoration: line-through;
    }

    .item-status__item_stock_no {
      color: #333;
      background: #ffffff;
      border: 1px solid #000000;
      letter-spacing: 0.05em;
    }

  }
}
#page_product_goods .ec-productRole .item-status__item_price-rate{
      margin:0;
      font-size: 10px;
      width: 66px;
      margin-bottom: 0;
      @include media_desktop{
        width: 69px;
        margin-bottom: 2px;
      }
}

#page_product_goods .ec-productRole .item-status__item_price-rate,
#page_product_goods .ec-productRole .item-status__item{
      margin:0;
      font-size: 10px;
      width: 66px;
      margin-bottom: 0;
      padding: 2px 5px;
      @include media_desktop{
        width: 69px;
        margin-bottom: 2px;
      }
}

.js__megamenu-item .megamenu{
  z-index:10000;
}

.breadcrumb{
  background-color: #fff;
  border-radius:initial;
}

.pswp__cartin__button{

  position: absolute;
  bottom: 20px;
  width: 100%;

  .pswp__button-cart1__inner{
    display: flex;
  }

  .button-cart1__button{
    height: 40px;
    margin: 2px 5px 2px 10px;
    .button-cart1__txt{
      font-size: 12px;
    }
  }

  .button-favorite-rectangle__button{
    height: 40px;
    margin: 2px 10px 2px 5px;
    border-radius: 3px;

    .button-favorite-rectangle__txt{
      /*padding-left:0;*/
    }
  }
}

#page_product_goods{
#detailSoldOutRank{
  @include media_desktop{ 
      .contents__result-ranking{
        width: 100%;
        .result-ranking__inner{
          width: 100%;
        }
        .result-ranking {
          overflow: initial;
        }
      }
      .swiper-wrapper{
        flex-wrap: wrap;
      }
      .swiper-slide {
        width: 23% !important;
        margin-right: 10px;
        &:nth-of-type(4n){
          margin-right: 0;
        }
      }
      .swiper-button-prev,
      .swiper-button-next
      {
        display: none;
      }

  }
}
}  

.shippping-delivery-date{
  margin-bottom: 2px;

  @include media_desktop(){
    margin-bottom: 0px;
  }

  &__text{
    font-size: 12px;
  }

  &__day{
    /*color: #cc1c1c;*/
    font-weight: 600;
  }
}

.area-breadcrumb {

  margin: 0 auto;
  padding-bottom: 58px;
  @include media_desktop{
    width: 1100px;
  }

  @include media_desktop{
    .result-heading__title-enSub{
      /*font: 400 23px 'Poppins', sans-serif;*/
      text-align: left;
    }

    .contents__catagoryList{
      width: 100%;
      li{
        a{
          padding: 15px 10px;        
        }
      }
    }
  }
}

.contents__category_brand_item{
  margin-bottom: 48px;
}

.area-brand{
  @include media_desktop{
    width: 1100px;
    margin: 0 auto;
    padding: 0;

    .product-list__item{
      width: 198px;

      &:nth-child(4n){
        margin-right: 22px;
      }

      &:nth-child(5n){
        margin-right: 0;
      }
    }
  }
}

.result-guide{
  &__sortlist_top{
    margin-top: 10px;

    @include media_desktop(){
      margin-bottom: 5px;
    }

    ul.sortlist__main{
      /*
      display: flex;
      overflow-x:scroll;
      */

      &::-webkit-scrollbar{
        height: 3px;
      }

      &::-webkit-scrollbar-thumb{
        background: #878787;
      }

      li{
        white-space: nowrap;
        color: #878787;
        flex: 0 0 auto;
        width: fit-content;
        cursor: pointer;

        span{
          font-size: 12px;
          padding: 10px 13px;
          display: block;
        }

        &.selected{
          span{
            background-color: #3A90CA;
            color: #fff;
            border-radius: 50px;
          }
        }
      }
    }
    
    .swiper-pagination{
      margin-top: 2px;
    }
  }

  &__switch{
    float: left;
    background-color: #EFEFEF;
    padding: 2px 1px;
    border-radius: 50px;

    @include media_desktop{
      float: none;
    }
  }

  &__switch_top{
    margin-bottom: 10px;
  }
}
.ec-searchnavRole__actions{
  .result-guide{
    &__filters{
      float: right;
    }
  }
}

.listview_toggle{
  //transform: translate3d(-50%, -50%, 0);
  
  .toggle {
    cursor: pointer;
    display: inline-block;
    position: relative;
    width: 150px;
    height: 29px;
    background: #efefef;
    border-radius: 50px;
    @include media_desktop(){
      height: 38px;
    }

    &:before, &:after{
      position: absolute;
      line-height: 34px;
      font-size: 12px;
      z-index: 2;
      transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
      @include media_desktop(){
        line-height: 38px;
      }
    }
    //&::before{
    //  content: "１色表示";
    //  left: 15px;
    //  color: #3A90CA;
   // }
   // &::after{
   //   content: "全色表示";
   //   right: 15px;
   //   color: #878787;
   // }

  }

  .toggle__handler {
    display: inline-block;
    position: absolute;
    z-index: 1;
    background: #fff;
    width: 75px;
    height: 27px;
    border-radius: 50px;
    top: 2px;
    left: 3px;
    transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transform: translateX(0px);
    @include media_desktop(){
      top: 3px;
      height: 32px;
    }
  }

  .toggle__left {
    z-index: 2;
    position: absolute;
    font-size: 12px;
    width: 75px;
    border-radius: 50px;
    left: 2px;
    top: 0;
    height: 32px;
    line-height: 32px;
    color: #3A90CA;
    @include media_desktop(){
      left: 17px;
      line-height: 38px;
    }
  }

  .toggle__right {
    z-index: 2;
    position: absolute;
    font-size: 12px;
    width: 75px;
    border-radius: 50px;
    right: 2px;
    top: 0;
    height: 32px;
    line-height: 32px;
    color: #878787;
    @include media_desktop(){
      right: -11px;
      line-height: 38px;
    }
  }

  input:checked{
    + .toggle{
      //background: #66B317;
      .toggle__left {
        color: #878787;
      } 
      .toggle__right {
        color: #3A90CA;
      } 
    }



    + .toggle:before {
      color: #878787;
    }

    + .toggle:after {
      color: #3A90CA;
    }

    + .toggle .toggle__handler {
      width: 75px;
      transform: translateX(69px);
      border-color: #fff;
      @include media_desktop(){
        transform: translateX(70px);
      }
    }
  }

  #toggle_check{
    display: none;
  }
}

%crossSet{
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px; /* 棒の幅（太さ） */
  height: 10px; /* 棒の高さ */
  background: #333;
}

.category_color_link{
  .contents__catagoryList{
    li{
      a{
        padding: 12px 10px;

        ._cross{
          display: inline-block;
          position: relative;
          width: 10px;
          height: 10px;
          margin-right: 3px;

          &::before{
            @extend %crossSet;
            transform: translate(-50%,-50%) rotate(45deg);
          }

          &::after{
            @extend %crossSet;
            transform: translate(-50%,-50%) rotate(-45deg);
          }
        }
        
      }
    }
  }
}

.parent_child_coordinate.detailItem .area-related{
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.breadcrumb-list__item .arrow{
  font-family: zozofont,sans-serif;
  font-weight: bold;
  line-height: 1;
  color: #272828;
  display: inline-block;
  font-size: 0.8rem;
}