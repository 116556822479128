@import "../mixins/media";
@import "../mixins/clearfix";
@import "../mixins/projects";
@import "../mixins/forms";
@import "../mixins/price";
/*
注文内容確認

カート内 注文内容確認に関する Project コンポーネントを定義します。

ex [マイページ　注文詳細](http://demo3.ec-cube.net/shopping)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderRole

Styleguide 15.2
*/
.ec-orderRole{
  @include container;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  text-align: left;
  @include media_desktop {
    margin-top: 20px;
    flex-direction: row;
  }
  .ec-inlineBtn {
    font-weight: normal;
    padding: 4px 8px;
    font-size: 12px;
  }
  & &__detail{
    padding: 0;
    width: 100%;
    @include media_desktop {
      padding: 0 16px;
      width: 66.66666%;
    }
  }
  & &__summary{
    width: 100%;
    .ec-inlineBtn {
      display: inline-block;
    }
    @include media_desktop {
      width: 33.33333%;
      padding: 0 16px;
      .ec-inlineBtn {
        display: none;
      }
    }
  }
  .ec-borderedList {
    margin-bottom: 20px;
    border-top: 1px dotted #ccc;
    @include media_desktop {
      border-top: none;
    }
  }

  & &__detail{
    .ec-orderDelivery div.ec-orderDelivery__change{
      top:4px;
      right:10px;
    }


    .ec-orderAccount {
      position: relative;
      div.ec-orderAccount__change{
        position: absolute;
        top:50px;
        right: 0;
        display: none;

        @include media_desktop{
          display: inline-block;
          top:37px;
          right:10px;

        }
      }
    }
  }


}

/*
注文履歴詳細 オーダ情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderInfo

Styleguide 15.2.1
*/
.ec-orderOrder{
  margin-bottom: 30px;
  & &__items{
    @include borderBottom;
    @include borderTop;
  }
}

/*
注文履歴詳細 お客様情報

マイページ 注文詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAccount

Styleguide 15.2.2
*/
.ec-orderAccount{
  margin-bottom: 15px;
  p {
    font-size: 13px;
    margin-bottom: 0;
    @include media_desktop(){
      font-size: 14px;
    }
  }
  @include clearfix;
  & &__change{
    display: inline-block;
    margin-left: 10px;
    float: right;
  }
  & &__account {
    margin-bottom: 16px;
    padding: 0 10px;
  }

}


/*
注文詳細 配送情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　配送情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderDelivery

Styleguide 15.2.3
*/
.ec-orderDelivery{
  & &__title{
    padding: 10px 0 5px 10px;
    font-weight: normal;
    font-size: 14px;
    position: relative;
  }
  & &__change{
    display: inline-block;
    position: absolute;
    right: 0;
    top:0;
  }
  & &__items{
    @include borderBottom;
    @include borderTop;
  }
  & &__address{
    margin: 10px 0 18px ;
    padding: 0 10px;
    p{
      margin:0;
    }
  }
  & &__edit{
      padding: 0 10px;
      margin-bottom: 30px;
  }
  & &__item{
    .kmes{
      padding: 0 10px;
    }
  }
  & &__actions{
      padding: 0 10px;
  }

  .ec-imageGrid{
    border-top: none;
  }

  label{
  }

  .selector-inline{
    display:flex;
    align-items: center;
    margin-bottom: 6px;

    &__item{
        flex-basis: 150px;
      &:first-child{
        flex-basis: 80px;
      }
    }
  }

  .filters2-selector__select{
    margin-bottom: 0;
  }

}


/*
注文履歴詳細 支払情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　支払情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
.ec-orderRole
  .ec-orderPayment
    .ec-rectHeading
      h2 お支払方法
    p 支払方法： 郵便振替

Styleguide 15.2.4
*/
.ec-orderPayment{
  margin-bottom: 30px;
}


/*
注文履歴詳細 お問い合わせ

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　お問い合わせ(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
.ec-orderRole
  .ec-orderConfirm
    .ec-rectHeading
      h2 お問い合わせ
    p 記載なし

Styleguide 15.2.5
*/
.ec-orderConfirm{
  margin-bottom: 20px;
  @include media_desktop {
    margin-bottom: 0;
  }
  .ec-input {
    textarea {
      height: 96px;
    }
  }

}


/*
お届け先の複数指定

お届け先の複数指定に関するコンポーネントを定義します。

ex [マイページ　お届け先の複数指定](http://demo3.ec-cube.net/shopping/shipping_multiple)
(商品購入画面 → 「お届け先を追加する」を押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAddAddress

Styleguide 15.2.6
*/
.ec-AddAddress  {
  padding: 0 10px;
  @include media_desktop {
    margin: 0 10%;
  }

  & &__info {
    margin-bottom: 32px;
    text-align: center;
    font-size: 16px;
  }
  & &__add {
    border-top: 1px solid #f4f4f4;
    padding-top: 20px;
    margin-bottom: 20px;
  }
  & &__item {
    display: table;
    padding:16px;
    background: #f4f4f4;
    margin-bottom: 16px;
  }
  & &__itemThumb {
    display: table-cell;
    min-width: 160px;
    width: 20%;
    img {
      width: 100%;
    }
  }
  & &__itemtContent {
    display: table-cell;
    vertical-align: middle;
    padding-left: 16px;
    font-size:16px;
  }
  & &__itemtTitle {
    font-weight: bold;
    margin-bottom: 10px;
  }
  & &__itemtSize {
    margin-bottom: 10px;
  }
  & &__itemtPrice {

  }
  & &__itemtNumber {

  }
  & &__select {
    margin-bottom: 5px;
  }
  & &__selectAddress {
    display: inline-block;
    label {
      font-size: 16px;
      font-weight: normal;
    }
    select {
      min-width: 100%;
      @include media_desktop {
        min-width: 350px;
      }
    }
  }
  & &__selectNumber {
    display: inline-block;
    margin-left: 30px;
    label {
      font-size: 16px;
      font-weight: normal;
    }
    input {
      display: inline-block;
      margin-left: 10px;
      width: 80px;
    }
  }
  & &__actions {
    .ec-blockBtn--action {
      margin-bottom: 8px;
    }
  }
  & &__new {
    margin-bottom: 20px;
  }
}

.ec-orderCoupon{
  p.applicable{
    font-size: 13px;
  }
  .ec-ChangeBtn,.ec-input{
    padding: 0 10px;
  }

  p.con_ex{
    padding: 0 5px;
  }
}

//GMO
%gmoInp{
  font-size: 13px;
  border: 1px solid #272828;
  height: 30px;
}
.ec-orderPayment{
  .ec-input{
    input[type="tel"]{
     @extend %gmoInp;
    }
    input[type="text"]{
     @extend %gmoInp;
    }

    select{
      @include form-select();
    }
  }
  .ec-select-after{
    position: relative;
    width: 87px;
    select{
      margin-bottom: 0;
    }
    &::after{
      @include form-select-after();
    }
  }
  .dl_table{
    margin-top: 10px;
    padding: 0 10px;
    font-size: 12px;
    dt{
      width: 35%!important;
      @include media_desktop(){
        width: 30%!important;;
      }
    }
  }

  input[type="radio"]{
    +label{
      span{
        position: relative;
        padding-left: 18px;
        //font-size: 13px;

        &::before{
          content: "";
          display: block;
          position: absolute;
          left: 0px;
          top: 0px;
          width: 15px;
          height: 15px;
          border: 1px solid;
          background: #fff;
          border-radius:50%;
        }

      }
    }
  }

  input[type="radio"]:checked{
    +label{
      span{
        &::after{
          content: "";
          display: block;
          position: absolute;
          top: 4px;
          left: 4px;
          width: 7px;
          height: 7px;
          background: #000;
          border-radius:50%;
        }
      }
    }
  }

  input[id^="select_card_seq"]{
    appearance: radio;
  }
}

.ec-orderConfirm{
  .ec-input{
    padding: 5px 10px 0 10px;
    margin-bottom: 30px;
    textarea{
      font-size: 13px;
    }
  }
}

.ec-totalBox{
  &__specTotal{
    @include defaultPrice();
  }
  &__spec{
    dd{
      @include defaultPrice();
    }
    &.breakdown{
      dd{
        font-size: 11px;
        font-weight: 400;;
      }
    }
  }
  & &__price{
      @include defaultPrice();
      color: #E3476C!important;
      font-size: 20px;
  }
}

.ec-blockRadio{
  padding: 0 10px;
}

/*
ご注文手続き 株主優待クーポン

*/

.k3m-couponHeader{
  width: 100%;
  padding: 15px 10px 20px 10px;

  @include media_desktop{
  width: 1100px;
  margin: 20px auto 15px auto;
  padding: 0;
  }

  .coupon-header-inner{
    position: relative;
    background: transparent;
    width: 215px;
    height: 27px;
    border: 1px solid #3F3F3F;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;

    @include media_desktop{
      width: 260px;
      height: 31px;
      font-size: 14px;
    }

    &.right::after{
      content: '';
      width: 6px;
      height: 6px;
      border: 0px;
      border-top: solid 1px #3F3F3F;
      border-right: solid 1px #3F3F3F;
      transform: rotate(-135deg);
      position: absolute;
      top: 50%;
      left: 10px;
      margin-top: -4px;
    }

    &.left{

      margin: 0 10px 0 auto;

      @include media_desktop{
        margin: 0 0 0 auto;
      }

      &::after{
        content: '';
        width: 6px;
        height: 6px;
        border: 0px;
        border-top: solid 1px #3F3F3F;
        border-right: solid 1px #3F3F3F;
        transform: rotate(45deg);
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -4px;
      }
    }

    &__text{
      font-size: 14px;
      font-weight: normal;
      font-weight: 400;
      letter-spacing: 0.04em;
    }

    &__link{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.k3m-CouponExEditRole {
  width: 80%;
  .no-view{
	  display: none;
  }

  .no_applied_coupon{
		font-size: 14px;
	}

  .ec-RegisterRole__main{
    margin-bottom: 40px;
  }

  .ex_code {

    @include media_desktop {
      width: 400px;
    }
  }

  ul.k3m-itemInput__coupon_ex_code{
    display: flex;

    li.ex_code{
      margin-right: 20px;
    }
  }

  div.cn_list-top{
    max-width: 600px;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;

    .ec-errorMessage{
      font-size: 0.7rem;
      font-weight: bold;
      color: #DE5D50;
      padding-left: 5px;
      position: relative;
      top: -5px;

      @include media_desktop{
        font-size: 0.8rem;
        padding-left: 10px;
        top: -10px;
      }
    }
  }

  div.cn_list{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .k3m-couponExArea{
    margin-top: 40px;

    &_list {
      li.k3m-couponExArea_list_li:last-child div.cn_list-top{
        border-bottom: 1px solid #ccc;
      }
    }
  }

  div.cn_list div.cn_left{
    padding:10px 5px;
    font-size: 12px;

    @include media_desktop {
      padding:15px 10px;
      font-size: 14px;
    }

    border-right: none;
	  padding-right: 30px;
	  flex-basis: 85%;
    padding-right:20px;

    span.code::after{
      font-size: 12px;
//		  content: "\A";
		  white-space: pre;

      @include media_desktop{
        font-size: 14px;
//        content: " / ";
        white-space: normal;
      }
    }

  }

  div.cn_list div.cn_right{
    padding:10px 5px;
    font-size: 12px;

    @include media_desktop {
      padding:15px 10px;
      font-size: 14px;
    }

    border-left: none;
    text-align: center;
    flex-basis: 15%;

  }

}

.coupon_edit_page{
  .ec-pageHeader{
    h1{
      margin: 10px 16px 8px;
    }
  }
}
.coupon_ex_edit_page{
  .ec-pageHeader{
    h1{
      margin: 10px 16px 8px;
    }
  }
}

.ec-CustomerChangeRole{
  h1 {
    text-align: center;
  }
  .ec-blockBtn--action{
    margin-bottom: 16px;
  }
}

.ec-DeliveryChangeRole{
  h1 {
    text-align: center;
  }
  .ec-blockBtn--action{
    margin-bottom: 16px;
  }
}