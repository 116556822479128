
.k3m_cartInForm{

    .no-cart{
      display: none;
    }

    dl{
      dt{
        padding-top: 5px;
        border-top: 1px solid #dcdcdc;
        text-align: left;
        &:first-child{
          border-top: none;
        }
      }
      dd{
        text-align: left;
      }
    }

    button{
      &.ec-blockBtn--action{
        width: 100%;
      }
    }

    ul.k3m-item{
      display: flex;

      li{

        // カラーチップ表示箇所
        &.image{
          flex-basis: 30%;
          margin-right: 10px;
          @include media_desktop(){
            flex-basis: 15%;
            margin-right: 20px;
            min-width: 80px;
          }

          .color-image{
            &.selected{
              border: 1px solid #000;
            }
            &:hover{
              border: 1px solid #000;
            }
          }
        }

        // サイズ・カートボタン表記箇所
        &.contents{
          flex-basis: 70%;
          font-size: 12px;
          @include media_desktop(){
           font-size: 14px;
           flex-wrap: auto;
          }

          .cartin{
            border-bottom: 1px solid #e9e9e9;
            margin: {
              top:10px;
              bottom: 5px;
            }
            padding-bottom: 15px;
            display: flex;
            flex-wrap: wrap;

            @include media_desktop(){
              flex-wrap: nowrap;
            }

            &:first-child{
              margin-top: 0px;
            }

            &:last-child{
              border-bottom: none;
            }

             &_left{
              width: 100%;
              margin-bottom: 5px;
              @include media_desktop(){
                width: 180px;
                margin: auto 0;
              }

              ul{
                display: flex;
                justify-content: space-between;
                align-items: center;
                li{
                  &:first-child{
                    flex-grow: 1;
                  }
                  &:last-child{
                    flex-grow: 0;
                  }
                }
              }
            }

             &_right{
              width: 100%;
              @include media_desktop(){
                width: auto;
              }
              ul{
                display: flex;
                li{
                    flex-basis: 15%;
                  &.cartBtn{
                    padding-right: 10px;
                    flex-basis: 70%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }



%modalItemSet{
  width: 100%;
  margin: 0 auto;

  @include media_desktop(){
    width: 40%;
    margin: 0 auto;
  }
}

.ec-productModalItemRole{
  $modalBaseBorderColor:#e9e9e9;
  $modalBaseBackgroundColor:#fff;

  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -200;
  overflow: auto;
  //background-color: rgba(0, 0, 0, 0.3);


  @include media_desktop(){
    display: none;
  }

  .no-cart{
    display: none;
  }

  &__bg{
     opacity: 0;
     position: fixed;
     left: 0;
     right: 0;
     top: 0;
     bottom: 0;
     z-index:-200;
     background: #000;

      &.modalin{
        z-index: 9996;
        opacity: 0.5;
      }
   }

   & &__main{
    transform: translateY(300px);
    transition: all 0.3s ease;
    display: block;

    @include media_desktop(){
      margin: 50px 0;
    }
   }

   & &__items{
     @extend %modalItemSet;

    border-radius: 2px;
    //height: 100%;
  }

  $top:&;
  &.fadeIn{
    opacity: 1;
    z-index: 9996;

    #{$top}__main{
      padding: 0 13px;
      transform: translateY(0);
      z-index: 9998;
    }

    .k3m-productSize{
      background-color: $modalBaseBackgroundColor;
      padding: 10px;

      @include media_desktop(){
        margin-top: 0;
      }
    }

  }

  .color-image{
    &:hover{
      border: none!important;
    }
  }

  & &__header{
    @extend %modalItemSet;
    margin-top: 30px;
    position: relative;
    background-color: $modalBaseBackgroundColor;
    padding: 0;
    border-bottom: 1px solid $modalBaseBorderColor;
    text-align: center;

    &._category{
      padding: 15px 0;
    }

    h2{
      line-height: 1;
    }
  }

  & &__footer{
    @extend %modalItemSet;
    margin-top: 19px;
    margin-bottom: 30px;
    padding: 7px 0;

    width: 250px;

    @include media_desktop(){
      margin-top: 19px;
      width: 500px;

    }

  }

  img{
    width: 100%;
  }

  .k3m_cartInForm{
    ul.k3m-item li.contents .cartin_left{
      @include media_desktop(){
        width: 100%;
      }
    }
  }

  //Ange用に追加
  .itemlist-info{
    &__button-favorite{
      position: static;
    }
  }

  .modal-dialog__button-modal-close{
    right: 10px;
    @include media_desktop(){
      top: 10px;
    }
  }

  .modal-order-box{
    &__heading{
      font-size: 12px;
      font-weight:normal;
      font-weight: 400;
      color:#636363;
      text-align:left;
      background:#EFEFEF;
      padding:13px 15px;

      @include media_desktop(){
        font-size: 16px;
        text-align: center;
      }
    }

    &__inner{
      background-color: #fff;
      overflow: initial;
      max-height: initial;
    }
  }

  .left-favorite-in{
    &__main{
      position: relative;
    }
    &__icon{
      position: absolute;
      top:-2px;
      right: 0;
    }

    .select-size-box__button-favorite{
      width: 100%;
    }

    .select-size-box__size-stock-text {
      padding:5px 0;
    }
  }

  .button-modal-close-bottom{
    border:1px solid #fff;
    width:250px;
    padding: 7px 0;
    display:inline-block;
    text-align:center;
    font-size: 0;

    @include media_desktop(){
      display:block;
      &__inner{
        padding: 10px 0;
      }
    }




    &__text{
        //padding-left:18px;
        color:#fff;
        font-size:10px;
        font-weight:bold;
        font-weight:600;
        position:relative;
        display:inline-block;
        vertical-align: middle;
        margin-left: -6px;
        position:relative;
        top:1px;

        @include media_desktop(){
          font-size: 14px;
        }
    }
  }

  .button-modal-close{
    display: block;
    width: 21px;
    height: 21px;
    position: absolute;
  }

  .button-modal-close::before, .button-modal-close::after{
    content: "";
    display: block;
    width: 100%;/*バツ線の長さ*/
    height: 1px;/*バツ線の太さ*/
    background: #272828;
    transform: rotate(45deg);
    transform-origin:0% 50%;
    position: absolute;
    top: calc(14% - 0px);
    left: 14%;
}
.button-modal-close::after{
    transform: rotate(-45deg);
    transform-origin:100% 50%;
    left: auto;
    right: 3px;
}

  .modal-dialog{
    &__button-modal-close-bottom{
      position:relative;
      margin-top:19px;
    }
  }
}