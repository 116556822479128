@import "../mixins/variables";
@import "../mixins/clearfix";
@import "../mixins/media";

@mixin row{
  display: block;
  margin: 0;
  @include media_desktop {
    display: flex;
  }
}

@mixin makeSmColumn($columns){
  position: relative;
  min-height: 1px;

  @media (min-width: $desktop) {
    width: percentage(($columns/ 12));
  }
  @include media_desktop{
  }

}

/*
グリッド

画面を12分割し、グリッドレイアウトに対応するためのスタイルです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 5.1
*/

/*
2分割グリッド

画面 ２分割の　グリッドです。
Bootstrap の col-sm-6 相当のグリッドを提供します。

Markup:
.ec-grid2
  .ec-grid2__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid2__cell
  .ec-grid2__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid2__cell

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 5.1.1
*/
.ec-grid2{
  @include row;
  & &__cell{
    @include makeSmColumn(6);
  }
  & &__cell2{
    @include makeSmColumn(12);
  }
}
/*
3分割グリッド

画面　３分割の　グリッドです。


Markup:
.ec-grid3
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell

Styleguide 5.1.2
*/
.ec-grid3{
  @include row;
  & &__cell{
    @include makeSmColumn(4);
  }
  & &__cell2 {
    @include makeSmColumn(8);
  }
  & &__cell3 {
    @include makeSmColumn(12);
  }
}

/*
4分割グリッド

画面　４分割の　グリッドです。


Markup:
.ec-grid4
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell

Styleguide 5.1.3
*/
.ec-grid4{
  @include row;
  & &__cell{
    @include makeSmColumn(3);
  }
}

/*
6分割グリッド

2つにまとめた cell2 や 3つをまとめた cell3 タグも使用可能です。


Markup:
.ec-grid6
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
.ec-grid6
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
.ec-grid6
  .ec-grid6__cell3(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell3
  .ec-grid6__cell3(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell3

Styleguide 5.1.4
*/
.ec-grid6{
  @include row;
  & &__cell{
    @include makeSmColumn(2);
  }
  & &__cell2{
    @include makeSmColumn(4);
  }
  & &__cell3{
    @include makeSmColumn(6);
  }
}

/*
中央寄せグリッド 10/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の１０グリッドです

ex [ご利用規約ページ　本文](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-off1Grid
  .ec-off1Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.5
*/
.ec-off1Grid{
  margin: 0;
  @include media_desktop {
    @include row;
  }
  & &__cell{
    margin: 0;
    @include media_desktop {
      @include makeSmColumn(10);
      margin-left: percentage((1 / 12));
    }
  }
}


/*
中央寄せグリッド 8/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の８グリッドです


Markup:
.ec-off2Grid
  .ec-off2Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.6
*/
.ec-off2Grid{
  @include row;
  & &__cell{
    margin: 0;
    @include media_desktop {
      @include makeSmColumn(8);
      margin-left: percentage((2 / 12));
    }
  }
}
/*
中央寄せグリッド 6/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の６グリッドです


Markup:
.ec-off3Grid
  .ec-off3Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.7
*/
.ec-off3Grid{
  @include row;
  & &__cell{
    margin: 0;
    @include media_desktop {
      @include makeSmColumn(6);
      margin-left: percentage((3 / 12));
    }
  }
}
/*
中央寄せグリッド 4/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の４グリッドです


Markup:
.ec-off4Grid
  .ec-off4Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod


Styleguide 5.1.8
*/
.ec-off4Grid{
  @include row;
  & &__cell{
    margin: 0;
    @include media_desktop {
      @include makeSmColumn(4);
      margin-left: percentage((4 / 12));
    }
  }
}

/*
グリッドオプション

グリッドのセルに対して「左寄せ」「中央寄せ」「右寄せ」のオプションを付与することができます。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Styleguide 5.1.9
*/

/*
グリッドセルの左寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--left
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.10
*/
.ec-grid--left {
  justify-content: flex-start;
}
/*
グリッドセルの右寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--right
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.11
*/
.ec-grid--right {
  justify-content: flex-end;
}
/*
グリッドセルの中央寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--center
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.12
*/
.ec-grid--center {
  justify-content: center
}

@mixin guidePageNaviA($background) {
  position: relative;
  color: #666;
  text-decoration: none;
  line-height: 5;
  display: block;
  height: 80px;
  width: 100%;

  &::before{
    position: absolute;
    left: 10px;
    top:10px;
    content:"";
    display: inline-block;
    width: 50px;
    height: 50px;
    background:$background;
  }
}

.ec-guideRole{
  margin-top: 20px;
  margin-bottom: 60px;

  &__navtxt{
    width: 210px;
    position: absolute;
    display: block;
    top: -10px;
    left: 0;

    &._small{
      width: 150px;
    }

    @include media_desktop(){
      width: 238px;

      &._small{
        width: 160px;
      }
    }
  }

  &__navblock{
    position: relative;
    margin-top: 30px;
    background: #f9f4ec;
    border-radius: 6px;
    padding: 43px 0 19px;
  }

  &__pageNavi{
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;

    &_items{
      width: 100%;
      letter-spacing: 0;
      margin: 0 5px 10px 5px;
      border: 1px solid #e4dacb;
      background: #fff;
      padding: 4px 0;
      height: 80px;

      @include media_desktop(){
        width: 262px;
      }

      .txt{
        padding-left: 65px;
      }

      a.delivery{
        @include guidePageNaviA(url(../images/guide/emguide_nav_img_01.gif) center center/50px auto no-repeat);
      }
      a.free{
        @include guidePageNaviA(url(../images/guide/emguide_nav_img_02.gif) center center/50px auto no-repeat);
      }
      a.payment{
        @include guidePageNaviA(url(../images/guide/emguide_nav_img_03.gif) center center/50px auto no-repeat);
      }
      a.cancel{
        @include guidePageNaviA(url(../images/guide/emguide_nav_img_05.gif) center center/50px auto no-repeat);
      }
      a.member{
        @include guidePageNaviA(url(../images/guide/egguide_nav_img_minber.gif) center center/50px auto no-repeat);
        .txt{
          line-height: 1.4;
          position: absolute;
          top: 18px;
        }
      }
      a.service{
        @include guidePageNaviA(url(../images/guide/egguide_nav_img_service.gif) center center/50px auto no-repeat);
      }
      a.present{
        @include guidePageNaviA(url(../images/guide/egguide_nav_img_pre.gif) center center/50px auto no-repeat);
      }
      a.contact{
        @include guidePageNaviA(url(../images/guide/emguide_nav_img_06.gif) center center/50px auto no-repeat);
      }
    }
  }

  &__h2{
    font-weight: bold;
    font-size: 18px;
    color: #69470b;
    background: #fde8e9;
    border-radius: 6px;
    margin-bottom: 20px;
    padding: 10px 5px;

  }

  &__h3{
    font-size: 16px;
    color: #69470b;
    position: relative;
    padding-left: 13px;
    margin-bottom: 10px;

    &._green::before {
      background: url(../images/guide/egguide_ico_h3_01.gif) center center/10px auto no-repeat;
    }

    &::before{
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      top:3px;
      left: 0;
      background: url(../images/guide/emguide_ico_h3_01.gif) center center/10px auto no-repeat;
    }
  }

  &__h4{
    font-size: 16px;
  }

  &__navitype{
    margin-top: 30px;

    &_items{
      font-size: 13px;

      @include media_desktop(){
        font-size: 14px;
      }

      em{
        font-weight: bold;
      }

      .telNumber {
        font-size: 16px;
        color: #5a9ae9;
        padding-right: 20px;
      }

      .table01{
        width: 100%;
        border-spacing: 0;
        text-align: center;
        border-right: 1px solid #e4dacb;
        border-top: 1px solid #e4dacb;

        .first{
          width: 75px;
          @include media_desktop(){
            width: initial;
          }
        }

        th{
          color: #69470b;
          text-align: center;
          background: #fbfaf7;
          border-left: 1px solid #e4dacb;
          border-bottom: 1px solid #e4dacb;
          padding: 10px;
          height: 57px;

        }

        td{
          border-left: 1px solid #e4dacb;
          border-bottom: 1px solid #e4dacb;
          padding: 10px 5px;
          height: 57px;
        }

      }

      .f{
        display: flex;
        flex-wrap: wrap;

        .detail_big{
          width: 200px;

          @include media_desktop(){
            width: 250px;
          }
        }

        @include media_desktop(){
          flex-wrap: nowrap;
        }
      }

      .fL{
        width: 100%;
        margin-bottom: 15px;
        @include media_desktop(){
          width: 60%;
          margin-right: 60px;
        }

        .iconImg{
          width: 100px;
        }
      }

      .fR{
        width: 100%;
        margin-bottom: 15px;

        @include media_desktop(){
          width: 40%;
        }
      }

      .pay_title{
        margin-bottom: 15px;
      }

      .guideBlockType01Inner{
        margin-bottom: 20px;
      }

      .pay_img{
        max-width: 379px;
      }

      .convini_img{
        max-width: 450px;
      }

      ._red{
        color:#e71019;
      }
    }

    &_mbimg{
      .img{
        width: 100%;

        @include media_desktop(){
          max-width: 1100px;
        }
      }
    }

    #accordion01_item{
      display: none;

      &._show{
        display: block;
      }
    }

    .biz_link{
      font-size: 12px;

      @include media_desktop(){
        font-size: 14px;
      }
    }



  }
}


.ec-firstEntryRole{
  margin-bottom: 60px;

  &__h2{
    background: url(../images/entry/mod_ttlTypeA01_bg_02.gif) 0 100% repeat-x;
    font-size: 200%;
    line-height: 1.4;
    margin-bottom: 20px;
    color: #69470b;
  }

  &__h3{
    margin-bottom: 20px;
  }

  .bg_welcome {
    display: block;
    width: 100%;
    position: relative;
    background: url(../images/entry/index_img_01_01.jpg) top center/100% no-repeat;
    height: 140px;


    @include media_desktop(){
      background: url(../images/entry/index_img_01_01.jpg) center center/100% no-repeat;
      width: 1100px;
      height: 340px;
      margin-bottom: 20px;
    }
  }

  .btn_newregist{
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: 0 auto ;
    width: 189px;

    @include media_desktop(){
      width: 379px;
      bottom: 70px;
    }

    a {
      position: relative;

      &::before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: url(../images/entry/index_btn_newregist_n.png) center center/189px no-repeat;
        width: 189px;
        height: 51px;

        @include media_desktop(){
          background: url(../images/entry/index_btn_newregist_n.png) center center no-repeat;
          width: 379px;
          height: 51px;
        }
      }

      &:hover::before{
        background: url(../images/entry/index_btn_newregist_r.png) center center/189px no-repeat;
        @include media_desktop(){
          background: url(../images/entry/index_btn_newregist_n.png) center center no-repeat;
        }
      }
    }

    @include media_desktop(){
      width: 379px;
    }
  }

  .box01{
    width: 100%;
    margin-bottom: 30px;

    @include media_desktop(){
      width: 1100px;
    }

    h4{
      width: 100%;
      margin-bottom: 10px;

      @include media_desktop(){
        width: 432px;
      }
    }

    .bg01{
      width: 100%;
      background: url(../images/entry/index_img_15.png) 100% 100% no-repeat;

      @include media_desktop(){
        width: 460px;
      }

    }
    .shop{
      position: relative;
      height: 140px;
      padding-left: 150px;
      @include media_desktop(){
        padding-left: 170px;
      }
    }

    .shopImg{
      width: 120px;
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .shopInner{
      position: absolute;
      top: 30px;
      display: block;

      .img016{
        width: 100%;
        padding-bottom: 10px;

        @include media_desktop(){
          width: 261px;
        }
      }
      .img05{
        width: 90%;
        @include media_desktop(){
          width: 180px;
        }
      }
    }
  }
  .box02{
    width: 100%;
    height: 100%;
    background: url(../images/entry/index_bg_730.png) 100% 100% no-repeat;
    position: relative;

    @include media_desktop(){
      background: url(../images/entry/index_bg_730.png) center center/100% no-repeat;
      width: 1100px;
      height: 625px;
      margin-bottom: 30px;
    }
  }

  .info{
    width: 100%;
    background: url(../images/entry/index_bg_27.png) 100% 100% no-repeat;
    padding: 0 5px 10px 5px;

    @include media_desktop(){
      background: url(../images/entry/index_bg_27.png) center center/100%  no-repeat;
      width: 1100px;
      height: 130px;
    }

    .img43,.img44{
      width: 461px;
    }

    .img47{
      width: 90%;
      margin: 0 auto;
      padding: 10px 0;

      @include media_desktop(){
        width: 523px;
      }
    }

    .info_main{
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      @include media_desktop(){
        width: 990px;
      }
    }
  }

  .item_indexmg{
    display: flex;
    flex-wrap: wrap;

    @include media_desktop(){
      margin-bottom: 30px;
    }

    .mR10{
      flex-basis: 47%;
      margin-right: 10px;

      @include media_desktop(){
        flex-basis: 308px;
      }
    }
  }

  .hide_text{
    display: none;
  }

  img{
    height: auto;
  }
}