@import "../mixins/media";
@import "../mixins/projects";
@import "../mixins/price";

/*
カート

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [カートページ](http://demo3.ec-cube.net/shopping)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartRole

Styleguide 15.1

*/
.ec-cartRole{
  @include container;
  margin-bottom: 10px;

  img{
    width: initial;
  }

  &::before{
    display: none;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  & &__progress{
    width: 100%;
    text-align: center;
  }
  & &__error{
    width: 100%;
    text-align: center;
    .ec-alert-warning {
      max-width: 80%;
      display: inline-block;
    }
  }
  & &__totalText{
    margin-bottom: 0;
    padding: 16px 0 6px;
    width: 100%;
    text-align: center;
    font-weight: normal;
    @include media_desktop {
      margin-bottom: 30px;
      padding: 0;
    }
  }
  & &__cart{
    margin: 0;
    width: 100%;
    @include media_desktop {
      margin: 0 10%;
    }

  }
  & &__actions{
    text-align: right;
    width: 100%;
    @include media_desktop {
      width:  20%;
      margin-right: 10%;
    }
  }
  & &__total{
    padding: 15px 0 30px ;
    font-weight: bold;
    font-size: 16px;

    p.contents{
      @include defaultPrice();
    }
  }
  & &__totalAmount{
    margin-left: 30px;
    color: #E3476C;
    font-size: 20px;
    @include media_desktop {
      font-size: 24px;
    }
  }

  .ec-blockBtn--action {
    margin-bottom: 10px;
  }
}


/*
カート商品表示枠（テーブルヘッダ）

カート内の商品をを表示するテーブル枠です。

ex [カートページ　テーブル部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartTable

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.1.2
*/
.ec-cartTable{
  display: table;
  border-top: 1px dotted #ccc;
  width: 100%;
  @include media_desktop {
    border-top: none;
  }
}


/*
カート商品表示枠（テーブルヘッダ）

カート内の商品を表示するテーブルのヘッダです。
スマホでは非表示となります。

ex [カートページ　カートテーブルヘッダ部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)


Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartHeader

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.3
*/
.ec-cartHeader{
  display: none;
  width: 100%;
  background: #F4F3F0;
  @include media_desktop {
    display: table-row;
  }
  & &__label{
    display: table-cell;
    padding: 16px;
    text-align: center;
    background: #F4F3F0;
    overflow-x: hidden;
    font-weight: bold;
  }
}
.ec-cartCompleteRole {
  @include container;
}
/*
カート内商品

カート内のアイテムを表示するテーブル行です。
スマホでは非表示となります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRow

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.4
*/

.ec-cartRow{
  display: table-row;
  text-align: left;

  & &__delColumn{
    border-bottom: 1px dotted #ccc;
    text-align: center;
    display: table-cell;
    width: 14%;
    vertical-align: middle;
    @include media_desktop{
      width: 8.3333333%;
    }
    .ec-icon {
      img {
        width: 1.5em;
        height: 1.5em;
        @include media_desktop {
          width: 1em;
          height: 1em;
        }
      }
    }
  }
  & &__contentColumn{
    border-bottom: 1px dotted #ccc;
    padding: 10px 0;
    display: table;
    @include media_desktop {
      display: table-cell;
    }
  }
  & &__img{
    display: table-cell;
    width: 40%;
    vertical-align: middle;
    padding-right: 10px;
    @include media_desktop {
      display: inline-block;
      min-width: 80px;
      max-width: 100px;
      padding-right: 0;
    }
  }
  & &__summary{
    display: table-cell;
    margin-left: 5px;
    //font-weight: bold;
    vertical-align: top;
    width: 46%;
    @include media_desktop {
      display: inline-block;
      margin-left: 20px;
      vertical-align: middle;
      width: 60%;
      min-width: 380px;
    }
    .ec-cartRow__name {
      margin-bottom: 5px;
    }
    .ec-cartRow__sutbtotalSP {
      display: block;
      font-weight: normal;
      @include media_desktop {
        display: none;
      }
    }
  }
  & &__amountColumn{
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    vertical-align: middle;
    text-align: center;
    width: 20%;
    @include media_desktop {
      width: 16.66666667%;
    }

    .ec-cartRow__amount {
      display: none;
      margin-bottom: 10px;
      @include media_desktop {
        display: block;
      }
    }
    .ec-cartRow__amountSP {
      display: block;
      margin-bottom: 10px;
      @include media_desktop {
        display: none;
      }
    }

    .ec-cartRow__amountUpDown {
      display: flex;
      justify-content: center;
      @include media_desktop {
        display: block;
      }
    }

    .ec-cartRow__amountUpButton {
      margin: 0 2px;
      display: inline-block;
      border: 2px solid #c9c9c9;
      border-radius: 50%;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #fff;


      .ec-cartRow__amountUpButton__icon {
        img {
          display: block;
          margin-left: -0.4em;
          width: .8em;
          height: .8em;
          position: absolute;
          top: 28%;
          left: 50%;
        }
      }
    }
    .ec-cartRow__amountDownButton {
      margin: 0 2px;
      display: inline-block;
      border: 2px solid #c9c9c9;
      border-radius: 50%;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #fff;

      .ec-cartRow__amountDownButton__icon {
        img {
          display: block;
          margin-left: -0.4em;
          width: .8em;
          height: .8em;
          position: absolute;
          top: 28%;
          left: 50%;
        }
      }
    }

    .ec-cartRow__amountDownButtonDisabled {
      @extend .ec-cartRow__amountDownButton;
      cursor: default;
    }
  }
  & &__subtotalColumn{
    display: none;
    border-bottom: 1px dotted #ccc;
    text-align: right;
    width: 16.66666667%;

    @include media_desktop {
      @include defaultPrice();
      display: table-cell;
    }

    span.saled{
      color: #E3476C!important;
    }
  }

  & &__name{
    text-align: left;
    &_label{
      font-size:11px;
      font-weight: 400;
      margin-bottom: 5px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;

      @include media_desktop(){
        font-size: 13px;
      }
    }
    &_classcategory{
      font-size:11px;
      font-weight: 400;

      @include media_desktop(){
        font-size: 13px;
      }
    }
  }

  & &__unitPrice{
    text-align: left;
    .normal{
      font-size: 16px;
      margin-right: 0;
    }
    .original{
      margin-right: 0;
    }
    .saled{
      color: #E3476C!important;
      font-size: 16px;
      margin-right: 0;
    }
  }
}

/*
カート内商品(商品が１の場合)

商品が１の場合はカート商品を減らす「-」ボタンの無効化状態になります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRowOnly

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.5
*/

.ec-cartRow{
  & &__amountColumn{
    .ec-cartRow__amountDownButtonDisabled {
      @extend .ec-cartRow__amountDownButton;
      cursor: default;
    }
  }
}

/*
アラート

カート内の商品に問題があることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartRole
  .ec-cartRole__cart
    +ec-alert-warning

Styleguide 15.1.6
*/

.ec-alert-warning {
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #F99;
  margin-bottom: 20px;


  & &__icon {
    display: inline-block;
    margin-right: 1rem;
    width: 20px;
    height: 20px;
    color: #fff;
    fill: #fff;
    vertical-align: top;
  }
  & &__text {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    position: relative;
  }
}

.ec-alert-warning-cart{
  width: 100%;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;

  & &__text {
    display: inline-block;
    font-size: 14px;
    color: #E73656;
    position: relative;

    @include media_desktop(){
      font-size: 16px;
    }
  }
}

.ec-alert-success{
  font-size: 14px;
  padding: 5px;
  @include media_desktop(){
    font-size: 16px;
    padding: 10px;
  }
}

.alert-dismissable .close,
.alert-dismissible .close{
  top:-1px;
  right: 0;
  @include media_desktop(){
    top:1px
  }
}


/*
アラート(空)

カートが空であることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-off3Grid
        .ec-off3Grid__cell
            +ec-alert-warningEnpty

Styleguide 15.1.7
*/
.ec-cartRole__total{
  .k3m-cartRole-line {
    p{
      &.title{
        width: 50%;
      }
      &.contents{
        width: 50%;
      }
    }
  }
}


.k3m-cartRole-earlyDiscount__check{
	display: block;
    text-align: left;
    cursor: pointer;
    font-size: 12px;
    margin-bottom: 15px;
}


div.ec-cartRole .ec-cartRole__actions {
  @include media_desktop(){
    width: 35%;
  }
}

/**************************************************
* ご注文画面
**************************************************/
#ec-totalBox__earlyDiscount p{
    font-size: 14px;
}

/**************************************************
* Newブロック
**************************************************/
div.ec-newItemRole .ec-newItemRole__listItem {
  @include media_desktop(){
    width: calc(100% / 5);
  }
}