@import "../mixins/media";
@import "../mixins/projects";
/*
商品一覧

商品一覧 に関する Project コンポーネントを定義します。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2

*/

.itemlist-icon{
  margin: 5px 0;
    .icon-list{
      display: flex;
      flex-wrap: wrap;
      &__cont{
        max-height: 17px;
        max-width: 17px;
        margin-right: 3px;
      }
  }
}

.ec-shelfRole{
  //@include container;


  // Ange用に追加
  .k3m-item-contents{
    .iconArea{
      &_item{
        li{
          margin-bottom: 0;
        }
      }
    }
  }



  $itemw:45px;

  .item-status{
    &__item{
      width: $itemw;

      &_newArrival{
        width: $itemw;
      }

      &_reserve{
        width: $itemw;
      }

      &_price-rate{
        width: $itemw;
        &.__price{
          width: 56px;
        }
      }

      &_stock_few{
        color: #fff;
        background: #ff7327;
        border: 1px solid #ff7327;
        letter-spacing: 0.05em;

        .item-status__txt{
          font-size: 8px;
        }
      }
    }
  }
}

/*
商品一覧グリッド

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2.1

*/
.ec-shelfGrid{
  /*
  @include reset_link;
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;

  @include media_desktop {
    margin-left: -16px;
    margin-right: -16px;
  }
  & &__item{
    margin-bottom: 36px;
    width: 50%;
    display: flex;
    flex-direction: column;
    &-image {
      height: 150px;
      margin-bottom: 10px;
      text-align: center;
      @include media_desktop() {
        height: 250px;
      }
    }
    img{
      width: auto;
      max-height: 100%;
    }
    @include media_desktop(){
      padding: 0 16px;
      width: 25%;
    }

    .ec-productRole__btn {
      margin-top: auto;
      margin-bottom: 15px;
    }
  }
  & &__item:nth-child(odd){
    padding-right: 8px;
    @include media_desktop(){
      padding: 0 16px;
    }
  }
  & &__item:nth-child(even){
    padding-left: 8px;
    @include media_desktop(){
      padding: 0 16px;
    }
  }
  & &__title {
    margin-bottom: 7px;
  }
  & &__plice {
    font-weight: bold;
  }
  */
}

/*
13.2.2 商品一覧グリッド（中央寄せ）

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。
商品のあまりはセンタリングされ、中央に表示されます。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGridCenter

Styleguide 13.2.2

*/

/*
.ec-shelfGridCenter{
  @include reset_link;
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  justify-content: center;

  @include media_desktop {
    margin-left: -16px;
    margin-right: -16px;
  }
  & &__item{
    margin-bottom: 36px;
    width: 50%;
    &-image {
      height: 150px;
      margin-bottom: 10px;
      text-align: center;
      @include media_desktop() {
        height: 250px;
      }
    }
    img{
      width: auto;
      max-height: 100%;
    }
    @include media_desktop(){
      padding: 0 16px;
      width: 25%;
    }

    .ec-productRole__btn {
      margin-top: auto;
      padding-top: 1em;
    }
  }
  & &__item:nth-child(odd){
    padding-right: 8px;
    @include media_desktop(){
      padding: 0 16px;
    }
  }
  & &__item:nth-child(even){
    padding-left: 8px;
    @include media_desktop(){
      padding: 0 16px;
    }
  }
  & &__title {
    margin-bottom: 7px;
  }
  & &__plice {
    font-weight: bold;
  }
}
*/

.item-status__item_price-rate.__price{
  width: 56px;
}

.ec-searchnavRole__infos{

  .guide-wrap{

    .pager{
      width: 350px;
    }

    &__color_view{

      padding-left: 20px;

      label{
        font-size: 14px;
        font-weight: normal;
        cursor: pointer;
      }

    }

    &__color_view_sp{
      background-color: #eeeeee;

      .color_view__box{
        display: block;
        text-align: right;
        margin-right: 10px;
        margin-bottom: 30px;
      }

      label{
        font-size: 12px;
        font-weight: normal;
        cursor: pointer;
        padding: 10px 0;
      }

    }

    &__result-guide{
      margin-bottom: 5px;
      @include media_desktop(){
        margin-bottom: 0px;
      }
    }

  }

  .display-sp{
    display: block;
    @include media_desktop(){
      display:none;
    }
  }

  .display-pc{
    display: none;
    @include media_desktop(){
      display:inline-block;
    }

  }
}