@import "../mixins/projects";
@import "../mixins/variables";
@import "../mixins/media";
@import "../mixins/menulist";
/*
マイページ

マイページで利用するためのスタイルガイド群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 9.1
*/

/*
マイページ

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist

Styleguide 9.1.1
*/
.ec-navlistRole2{
  position: relative;
  & &__navlist {
    @include reset_link;
    display: flex;
    flex-wrap: wrap;
    border-color: #D0D0D0;
    border-style: solid;
    border-width: 1px 0 0 1px;
    margin-bottom: 32px;
    padding: 0;
    list-style: none;
    @include media_desktop {
      flex-wrap: nowrap;
    }
  }

  & &__item{
    width: 50%;
    border-color: #D0D0D0;
    border-style: solid;
    border-width: 0 1px 1px 0;
    text-align: center;
    font-weight: bold;
    a {
      padding: 14px;
      width: 100%;
      display: inline-block;
      &:hover{
        background: #f5f7f8;
      }
    }
  }

  &__back{
    margin-bottom: 30px;
  }

  .active {
    a {
      color: #DE5D50;
    }
  }

  & &__logout{
    a{
      position: absolute;
      top:-25px;
      right: 0;
      font-weight: 900;
    }
  }
}

/*
マイページ（お気に入り機能無効）

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist_noFavorite

Styleguide 9.1.2
*/

/*
Welcome メッセージ

マイページで表示するログイン名の表示コンポーネントです。

ex [マイページ　メニューリスト下部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-welcomeMsg

Styleguide 9.1.3
*/
.ec-welcomeMsg{
  @include mypageContainer;
  margin: 1em 0;
  padding-bottom: 32px;
  text-align: center;
  @include borderBottom;

}

/*
お気に入り一覧

お気に入り一覧で表示するアイテムの表示コンポーネントです。

ex [マイページ　お気に入り一覧](http://demo3.ec-cube.net/mypage/favorite)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-favorite

Styleguide 9.1.4
*/
.ec-favoriteRole{
    margin-bottom: 60px;
  & &__header {
    margin-bottom: 16px;
  }
  & &__detail {
  }
  & &__itemList {
    @include reset_link;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
  }
  & &__item{
    margin-bottom: 8px;
    width: 47.5%;
    position: relative;
    box-sizing: border-box;
    padding: 10px;
    &-image {
      height: 150px;
      margin-bottom: 10px;
      text-align: center;
      @include media_desktop() {
        height: 250px;
      }
    }
    img{
      width: auto;
      max-height: 100%;
    }
    @include media_desktop(){
      width: 25%;
    }

  }
  & &__itemThumb {
    display: block;
    height:auto;
    margin-bottom: 8px;
  }
  & &__itemTitle{
    margin-bottom: 2px;
  }
  & &__itemPrice{
    font-weight: bold;
    margin-bottom: 0;
  }

  .product-list{
    display: flex;
    flex-wrap: wrap;

    &__item{
      display: block;
      margin-right: 15px;

      &:nth-child(2n){
          margin-right: 0;
      }

      @include media_desktop(){
        margin-right: 22px;

        &:nth-child(2n){
          margin-right: 22px;
        }

        &:nth-child(4n){
         margin-right: 22px;
        }

        &:nth-child(5n){
          margin-right: 0;
        }

      }

      .ec-closeBtn--circle {
        position: absolute;
        right: 10px;
        top: 10px;
        &:hover img{
          background: none;
        }
        .ec-icon img{
          width: 1em;
          height: 1em;
        }
      }

    }
    .itemlist-info__label{
      margin: 8px 8px 0 8px;
    }
    .itemlist-info__class{
      margin: 0 8px;
    }
  }

  .k3m-inlineBtn{
    margin-top: 3px;
    text-align: right;
  }
}

.ec-MypageMenuRole{
}



.menu-list{
  margin-bottom: 0;
  & &__name{
    @include menuList();
    background: #efefef;
    border-top: 1px solid #f4f4f4;
    padding: 12px 24px 12px 15px;
  }

  & &__box{
    @include menuList();
    border-bottom: 1px solid #C8C8C8;
    &:last-child{
      border-bottom: none;
    }

  }

  & &__link{
    box-sizing: border-box;
    width: 100%;
    display: inline-block;
    font-size: 11px;
    font-weight: 400;
    line-height: 1.4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 12px 24px 12px 15px;

    &:hover{
      color: #272828;
      text-decoration: none;
    }

    &::before{
      content: "";
      width: 6px;
      height: 6px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 17px;
      margin: auto;
      vertical-align: middle;
      border-top: 1px solid #272828;
      border-right: 1px solid #272828;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }


}

.ec-off1Grid.k3m-couponRole{
    margin-bottom: 60px;
    @include media_desktop(){
      .ec-off1Grid__cell{
        width: 100%;
        margin-left: 0;
      }
    }
}

.ec-editRole{
  margin-bottom: 60px;


  .ec-select{
    select{
      margin-bottom: 5px;
      @include media_desktop(){
        margin-bottom: 0;
      }
    }
  }

  .ec-radio input{
    margin-right: 0;
    @include media_desktop(){
      margin-right: 10px;
    }
  }
}

.ec-mypageDeliveryRole{
  margin-bottom: 60px;


  .ec-select{
    select{
      margin-bottom: 5px;
      @include media_desktop(){
        margin-bottom: 0;
      }
    }
  }

  .ec-radio input{
    margin-right: 0;
    @include media_desktop(){
      margin-right: 10px;
    }
  }
}

.ec-cardRole{
  .ec-select{
    select{
      margin-bottom: 5px;
      @include media_desktop(){
        margin-bottom: 0;
      }
    }
  }

  .ec-radio input{
    margin-right: 0;
    @include media_desktop(){
      margin-right: 10px;
    }
  }
  .ec-halfInput input[type='tel'], .ec-halfInput input[type='text']{
    width: 55%;
    @include media_desktop(){
      width: 45%;
    }
  }
}
