@import "../mixins/media";

/*
商品関連ブロッック

商品関連のブロックに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.2.block.pug
+ec-productSimpleRole

Styleguide 14.1
*/


.area-recently,
.area-related
{
    padding-bottom: 26px;

    @include media_desktop(){
        width: 1100px;
        margin: 0 auto;
        padding-bottom: 58px;
    }

    .product-list__item{
        width: -webkit-calc((100% - 18px) / 3);
        width: calc((100% - 18px) / 3);
        margin-right:9px;

        &:nth-child(2n){
            margin-right:9px;
        }

        &:nth-child(3n){
            margin-right:0;
        }
    }
}


.swiper-button-prev._non-icon,
.swiper-button-next._non-icon{
	top:130px;
}
