

@mixin choosy_color_pattern($color) {
    &+label{
        height: 15px;
        line-height: 15px;

        span::before{
          /*
          width: 15px;
          height: 15px;
          border-radius: 50%;
          content: " ";
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
          background: $color;
          */
        }
      }
}