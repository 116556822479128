@import "../mixins/btn";
@import "../mixins/media";

/*
ボタン

トップページで使用されているボタンのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.3
*/

/*
通常ボタン

インラインの要素としてボタンを定義出来ます。

Markup:
.ec-inlineBtn--top more

Styleguide 12.3.1
*/
.ec-inlineBtn--top{
  @include _btn(white, black, black);
}

/*
ロングボタン（全幅）

ロングタイプのボタンです。

Markup:
.ec-blockBtn--top 商品一覧へ

Styleguide 2.1.2
*/
.ec-blockBtn--top{
  @include _btn(white, black, black);
  display: block;
  height:56px;
  line-height:56px;
  padding-top: 0;
  padding-bottom: 0;

  @include media_desktop {
    max-width: 260px;
  }
}

%sidebar-search-box__submit_set{
  width: 44px;
  height: 30px;
  background: #272828;
  line-height: 30px;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 11px;
  font-weight: bold;
}

.sidebar-search-box{
  position: relative;
  &__submit[type="button"]{
    @extend %sidebar-search-box__submit_set;
  }
  &__submit2[type="button"]{
    @extend %sidebar-search-box__submit_set;
  }
}

.slide-search-menu__filters2{
  .sidebar-search-box__submit[type="button"],.sidebar-search-box__submit2[type="button"]{
    top:21px
  }
}