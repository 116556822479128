@import "../mixins/media";
/*
ユニサーチレコメンド

ユニサーチレコメンドで利用するためのスタイルガイド群です。

*/

.dv_recommend{
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px 0;
    @include media_desktop(){
        padding: 0;
    }

    @mixin a-deco(){
        text-decoration: none;
        color: #272828;

    }

    .dv_rc{
        &_header{
            flex-basis: 100%;
            &::after{
                content: "";
                font-family: 'Oswald', sans-serif;
                font-weight: normal;
                font-weight: 300;
                font-size: 25px;
                letter-spacing: 0.05em;
                margin-bottom: 10px;
                @include media_desktop(){
                    font-size: 35px;
                    letter-spacing: 0.02em;
                }
            }
        }

        &_title{
            content: "";
            flex-basis: 100%;
            font-size: 13px;
            font-weight: normal;
            letter-spacing: 0.05em;
            @include media_desktop(){
                font-size: 15px;
            }
        }

        &_items{
            a:active{
                @include a-deco();
            }
            a:hover{
                @include a-deco();
            }

        }

        &_description{
            display: none;
        }

        &_name{
            font-size: 12px;
            font-weight: 400;
            margin: 9px 0 3px;
            font-weight: normal;
            line-height: 1.5;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            min-height: 32px;
            width: 100%;
            text-align: left;
            @include media_desktop(){
                font-size: 13px;
                font-weight: 500;
                margin: 8px 0 8px;
            }
        }

        &_price{
            font-size: 17px;
            font-family: 'Oswald', sans-serif;
            font-weight: normal;
            font-weight: 400;
            color: #272828;
            display: inline-block;
            vertical-align: baseline;
            width: 100%;
            text-align: left;
            padding: 0 0 20px;
            @include media_desktop(){
                font-size: 18px;
            }

//            &::after{
//                content: "(税込)";
//                font-size: 10px;
//                font-family: "Noto Sans CJK JP", 'Noto Sans JP', "游ゴシック体", "Yu Gothic", "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
//                font-weight: normal;
//                font-weight: 400;
//                color: #272828;
//                letter-spacing: 0.05em;
//                @include media_desktop(){
//                    font-size: 12px;
//                }
//            }
        }
    }

}
