@import "../mixins/variables";
@import "../mixins/choosy_color";
/*
アイコン

デフォルトテンプレートのアイコンは`.ec-icon`>`img`タグで使用することができます

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Markup:
include /assets/tmpl/elements/4.1.icon.pug
div(style="background-color: rgba(130,130,130,.15); padding: 20px;")
  +icon-all

Styleguide 4.1
*/
.ec-icon img {
  max-width: 80px;
  max-height: 80px;
}

// レッド
.choosy-color-pattern-1{
  @include choosy_color_pattern(#ef5663);
}
// ブラック
.choosy-color-pattern-2{
  @include choosy_color_pattern(#313131);
}
// ブルー
.choosy-color-pattern-3{
  @include choosy_color_pattern(#4dbee9);
}
// ホワイト
.choosy-color-pattern-4{
  @include choosy_color_pattern(#fff);
  &+label{
    &::before{
      border:solid 1px #DCDCDC;
    }
  }
}
// グリーン
.choosy-color-pattern-5{
  @include choosy_color_pattern(#9ed563);
}
// ブラウン
.choosy-color-pattern-6{
  @include choosy_color_pattern(#81604C);
}
// グレー
.choosy-color-pattern-7{
  @include choosy_color_pattern(#AAB2BE);
}
// イエロー
.choosy-color-pattern-8{
  @include choosy_color_pattern(#fed14c);
}
// ピンク
.choosy-color-pattern-9{
  @include choosy_color_pattern(#f8afd7);
}
// オレンジ
.choosy-color-pattern-10{
  @include choosy_color_pattern(#f98140);
}
// パープル
.choosy-color-pattern-14{
  @include choosy_color_pattern(#ad8eef);
}
// その他
.choosy-color-pattern-15{
  &+label{
    /*padding-left: 20px;*/
  }
}