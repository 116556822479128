.k3m-favorite-main{
	.k3m-icon-varies{
		perspective: initial;
		max-width: initial;
		max-height: initial;
		position: relative;

		.k3m-balloon{
			position: absolute;
			top:-30px;
			left:-29px;
		}


		.button-favorite{
			cursor: pointer;
		}

    .button-favorite.is_favorite{
        background-color: initial;
        transform: none;

        background:url(../img/icon-favorite-fill.svg) center center/18px auto no-repeat;

        @include media_desktop(){
            background:url(../img/icon-favorite-fill.svg) center center/23px auto no-repeat;
        }
	}

	.button-favorite2.is_favorite{
		background-color: initial;
		transform: none;

		background:url(../img/icon-cart-favorite-fill-sp.svg) center center / 25px 25px no-repeat;
		@include media_desktop(){
			background:url(../img/icon-cart-favorite-fill-pc.svg) center center / 30px 30px no-repeat;
		}
	}
  }

  &._normal{
	.k3m-icon-varies{
		.button-favorite.is_favorite{
			background: none;

			.button-favorite-rectangle__txt{
				color: #E3476C;
				&::before{
					background:url(../img/icon-favorite-fill.svg) center center/18px auto no-repeat;
				}
			}
		}

	}
  }
}