@mixin menuList(){
  font-size: 11px;
  font-weight: normal;
  font-weight: 400;
  display: inline-block;
  text-align: left;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}