@import "../mixins/media";
/*
会員登録

新規会員登録 に関する Project コンポーネントを定義します。

ex [新規会員登録画面　会員登録](http://demo3.ec-cube.net/entry)

Markup:
include /assets/tmpl/elements/19.1.register.pug
+ec-registerRole

Styleguide 19.1

*/
.ec-registerRole{
  @include container;
  & &__actions {
    padding-top:20px;
    text-align: center;
    @include media_desktop {
      text-align: left;
    }
    p {
      margin-bottom: 16px;
    }
  }
  .ec-blockBtn--action {
    margin-bottom: 16px;
  }

  .ec-select{
    select{
      margin-bottom: 5px;
      @include media_desktop(){
        margin-bottom: 0;
      }
    }
  }

  .ec-radio input{
    margin-right: 0;
    @include media_desktop(){
      margin-right: 10px;
    }
  }

  .no-view-address-btn{
    display: block;
      width: 62px;

      .ec-addressList__action{
        a.ec-inlineBtn{
          width: 62px;
        }
      }
  }
}
.ec-registerCompleteRole {
  @include container;
}